import React from 'react'
import TuneIcon from "@material-ui/icons/Tune"
import CropOriginalIcon from '@material-ui/icons/CropOriginal'
import ImportExportIcon from "@material-ui/icons/ImportExport"

type panelMobileType = {
   toggleFilterDrawerOpen:any
   displayMode: any
   handleSwitchDisplay: any
   handleApplyFilters: any
   toggleOrderDrawerOpen: any
}

const panelMobile: React.FC<panelMobileType> = ({toggleFilterDrawerOpen, displayMode, handleSwitchDisplay, toggleOrderDrawerOpen}) => {
   return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: '40px' }}>
         <div onClick={toggleFilterDrawerOpen} style={{ backgroundColor: '#fff', borderBottom: '3px solid #f8f7f6', width: '33.33%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #f8f7f6' }}>
            <TuneIcon />
            Filtrar
         </div>
         <div onClick={()=>{handleSwitchDisplay()}} style={{ backgroundColor: '#fff', borderBottom: '3px solid #f8f7f6', width: '33.33%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #f8f7f6', textTransform: 'capitalize' }}>
            <CropOriginalIcon />
            {displayMode}
         </div>
         <div onClick={()=>{toggleOrderDrawerOpen()}} style={{ backgroundColor: '#fff', borderBottom: '3px solid #f8f7f6', width: '33.33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ImportExportIcon />
            Ordenar
         </div>
      </div>
   )
}

export default panelMobile
