import React, { useState, useCallback, useEffect } from 'react'
import { useInView } from "react-intersection-observer"
import { CategoryProductType, SelectedFilterType } from "types"
import { MICROSERVICE_GET_CATEGORY_PRODUCTS, SOCKET_NODES } from "../../../userglobals"
import {
  Breadcrumb,
  PanelMobile,
  ProductCard,
  FilterDrawer,
  SelectedFiltersPanel,
  OrderDrawer
} from './components'
import io from "socket.io-client"
import DisplayStyle from './DisplayMode.module.scss'
import ClassNames from 'classnames/bind'
import axios from 'axios'

type CategoryTypeProps = {
  category: any
  categoryProducts: any
  filters: any
}

let socket: SocketIOClient.Socket;
let cx = ClassNames.bind(DisplayStyle);


const Category: React.FC<CategoryTypeProps> = ({ category, categoryProducts, filters }) => {

  const [ref, inView, entry] = useInView()
  const [displayMode, setDisplayMode] = useState('mosaico')
  const [categoryState, setCategoryState] = useState<{
    page: number,
    maxPages: number,
    visualFilters: any[],
    selectedFilters: SelectedFilterType[],
    visibleProducts: CategoryProductType[],
    loading: boolean,
    order: string,
    isDrawerFilterOpen: boolean,
    isDrawerOrderOpen: boolean

  }>({
    page: 1,
    maxPages: category.numberOfPages,
    visualFilters: [],
    selectedFilters: [],
    visibleProducts: categoryProducts,
    loading: false,
    order: 'default',
    isDrawerFilterOpen: false,
    isDrawerOrderOpen: false,

  })

  const emitJoin = useCallback(() => {
    socket.emit(
      "join",
      { categoryId: category.id })
  }, [category.id])

  useEffect(() => {
    //socket = io(MICROSERVICE_GET_CATEGORY_PRODUCTS);
    socket = io(SOCKET_NODES, { transports: ['websocket'] });
    emitJoin();
    setCategoryState(prevState => ({
      ...prevState,
      isMounted: true,
    }));


  }, [])

  useEffect(() => {

    if (inView === true && categoryState.page < categoryState.maxPages) {

      setCategoryState(prevState => ({
        ...prevState,
        loading: true,
        error: false,
      }));
      //console.log('Emit Get Page');
      socket.emit('get-page', { categoryId: category.id, filters: categoryState.selectedFilters, page: categoryState.page + 1, order: categoryState.order }, (products: { page: ConcatArray<CategoryProductType>, numberOfPages: number }) => {
        //console.log(products)
        setCategoryState(prevState => ({
          ...prevState,
          page: categoryState.page + 1,
          loading: false,
          visibleProducts: categoryState.visibleProducts.concat(
            products?.page
          ),
          maxPages: products.numberOfPages
        }))

      });

    }
  }, [inView])

  const toggleFilterDrawerOpen = () => {
    setCategoryState(prevState => ({
      ...prevState,
      isDrawerFilterOpen: !categoryState.isDrawerFilterOpen,
      visualFilters: categoryState.selectedFilters
    }));
  }
  const toggleOrderDrawerOpen = () => {
    if (categoryState.isDrawerOrderOpen) {
      setCategoryState(prevState => ({
        ...prevState,
        isDrawerOrderOpen: false,
      }));
      getProductsFiltered(categoryState.selectedFilters, categoryState.order)
    } else {
      setCategoryState(prevState => ({
        ...prevState,
        isDrawerOrderOpen: true,
      }));
    }

  }
  const handleSwitchDisplay = () => {
    let newDisplay = "";
    if(displayMode === 'mosaico') {
      newDisplay = 'galeria'
    }
    if (displayMode === 'galeria') {
      newDisplay = 'lista';
    }
    if (displayMode === 'lista') {
      newDisplay = 'mosaico';
    }
    setDisplayMode(newDisplay)
  }
  function handleFilter(filterAttribute: string, filterValue: string, filterLabel: string, attributeLabel: string) {
    //console.log('ok')
    const copySelectedFilters = [...categoryState.visualFilters];
    if (categoryState.visualFilters.find((x: any) => x.attribute === filterAttribute && x.value === filterValue)) {
      // se filterAttribute e filter value já está em selectedFilters
      //  então remova o filtro
      const filterIndex = categoryState.visualFilters.findIndex((x: any) => x.attribute === filterAttribute && x.value === filterValue);
      copySelectedFilters.splice(filterIndex, 1);

    } else if (categoryState.visualFilters.find((x: any) => x.attribute === filterAttribute && x.value !== filterValue)) {
      // se filterAttribute já está em selectedFilters mas filter value não
      // 
      const filterIndex = categoryState.visualFilters.findIndex((x: any) => x.attribute === filterAttribute && x.value !== filterValue);
      copySelectedFilters[filterIndex].value = filterValue;
      copySelectedFilters[filterIndex].attributeLabel = attributeLabel;
      copySelectedFilters[filterIndex].filterLabel = filterLabel;

    } else {
      copySelectedFilters.push({ attribute: filterAttribute, value: filterValue, filterLabel: filterLabel, attributeLabel: attributeLabel })
    }
    setCategoryState(prevState => ({
      ...prevState,
      visualFilters: copySelectedFilters
    }));
  }
  const getProductsFiltered = (filters: any, order: string) => {
    setCategoryState(prevState => ({
      ...prevState,
      productsLoading: true
    }))

    axios.post(MICROSERVICE_GET_CATEGORY_PRODUCTS + "push-redis/", { categoryId: category.id, filters: filters, page: 1, order: order })
      .then(responseAA => {
        //console.log(responseAA.data)
        setCategoryState(prevState => ({
          ...prevState,
          visibleProducts: responseAA.data.page,
          page: 1,
          maxPages: responseAA.data.numberOfPages,
          productsLoading: false
        }))
        if (window.scrollY === 0) {
          window.scrollTo(0, 1);
        } else {
          window.scrollTo(0, 0);
        }
      })
  }
  const handleApplyFilters = () => {

    setCategoryState(prevState => ({
      ...prevState,
      selectedFilters: categoryState.visualFilters,
      isDrawerFilterOpen: false,
    }))
    getProductsFiltered(categoryState.visualFilters, categoryState.order);
  }
  function removeFilterOnly(filterAttribute: string, filterValue: string) {
    const copySelectedFilters = [...categoryState.selectedFilters];
    const filterIndex = categoryState.selectedFilters.findIndex(x => x.attribute === filterAttribute && x.value === filterValue);
    copySelectedFilters.splice(filterIndex, 1);

    setCategoryState(prevState => ({
      ...prevState,
      selectedFilters: copySelectedFilters,
      visualFilters: copySelectedFilters
    }))
    getProductsFiltered(copySelectedFilters, categoryState.order);
  }
  function removeAllFilters() {
    setCategoryState(prevState => ({
      ...prevState,
      selectedFilters: [],
      visibleProducts: categoryProducts,
    }))
  }
  function removeAllVisualFilters() {
    setCategoryState(prevState => ({
      ...prevState,
      visualFilters: []
    }))
  }
  const setOrder = (nome: string) => {
    setCategoryState(prevState => ({
      ...prevState,
      order: nome
    }));
  }

  let idx = 0;
  return (
    <div>
      <Breadcrumb breadcrumbItems={category.breadCrumbItems} />
      {categoryState.isDrawerFilterOpen
        &&
        <FilterDrawer
          isDrawerFilterOpen={categoryState.isDrawerFilterOpen}
          toggleFilterDrawerOpen={toggleFilterDrawerOpen}
          filters={filters}
          handleFilter={handleFilter}
          visualFilters={categoryState.visualFilters}
          handleApplyFilters={handleApplyFilters}
          removeAllVisualFilters={removeAllVisualFilters}
        />}
      {categoryState.isDrawerOrderOpen
        &&
        <OrderDrawer
          isDrawerOrderOpen={categoryState.isDrawerOrderOpen}
          toggleOrderDrawerOpen={toggleOrderDrawerOpen}
          setOrder={setOrder}
          order={categoryState.order}
        />
      }
      <PanelMobile toggleFilterDrawerOpen={toggleFilterDrawerOpen} displayMode={displayMode} handleSwitchDisplay={handleSwitchDisplay} handleApplyFilters={handleApplyFilters} toggleOrderDrawerOpen={toggleOrderDrawerOpen} />
      <SelectedFiltersPanel removeAllFilters={removeAllFilters} removeFilterOnly={removeFilterOnly} selectedFilters={categoryState.selectedFilters} />
      <div style={{ display: 'flex' }}>

        <div style={{ width: '100%' }}>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              categoryState.visibleProducts.map((product: any, index: number) => {
                const ClassName = cx({
                  cardGaleria: displayMode === 'galeria',
                  cardLista: displayMode === 'lista',
                  cardMosaico: idx % 2 === 0 && displayMode === 'mosaico',
                  lastCardMosaico: idx % 2 !== 0 && displayMode === 'mosaico',
                });
                idx = idx + 1;
                if (categoryState.visibleProducts.length === index + 1) {
                  return (
                    <div key={index} ref={ref} className={ClassName}>
                      <ProductCard key={idx} product={product} displayMode={displayMode} />
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className={ClassName}>
                      <ProductCard key={idx} product={product} displayMode={displayMode} />
                    </div>

                  )
                }
              })
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default Category
