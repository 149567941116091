import React from 'react'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

type VisualFiltersPanelType = {
  visualFilters: any
  handleFilter: any
  handleApplyFilters: any
  removeAllVisualFilters: any
}

const VisualFiltersPanel:React.FC<VisualFiltersPanelType> = ({visualFilters, handleFilter, handleApplyFilters, removeAllVisualFilters}) => {
  return (
    <div style={{padding: '10px 15px 0'}}>
      <div style={{ padding: '4px 0', display: 'flex', flexWrap: 'wrap' }}>
        {visualFilters.map((filtro: any, index: any) => {
          //console.log(filtro)
          return (
            <div
              key={index}
              style={{ backgroundColor: '#fbece8', padding: '3px 10px', borderRadius: '5px', margin: '0 8px 8px 0', cursor: 'default', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span style={{ marginRight: '3px', fontSize: '13px' }}>{filtro.attributeLabel} </span>
              <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleFilter(filtro.attribute, filtro.value, '', filtro.attributeLabel)}>
                <CloseRoundedIcon style={{ width: '14px' }} />
              </span>
            </div>
          )
        })}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div onClick={()=>{removeAllVisualFilters()}} style={{ marginTop: '10px', cursor: 'pointer' }}>{visualFilters.length > 0 && <span style={{ color: '#e8927c' }}>LIMPAR</span>}</div>
      <div onClick={()=>{handleApplyFilters()}} style={{ marginTop: '10px', cursor: 'pointer', display: 'flex', justifyContent: 'flex-end' }}>{visualFilters.length > 0 &&<span style={{ color: '#e8927c' }}>APLICAR FILTROS</span>}</div>
      </div>
    </div>
  )
}

export default VisualFiltersPanel
