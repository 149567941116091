import React from "react"
import Drawer from "@material-ui/core/Drawer"
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import styles from '../../Category.module.scss'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Button from "@material-ui/core/Button"


type DrawerCategoryType = {
  isDrawerOrderOpen: boolean
  toggleOrderDrawerOpen: () => void
  setOrder: (nome: any) => void,
  order: string
}

const CategoryFilterDrawer: React.FC<DrawerCategoryType> = ({
  isDrawerOrderOpen,
  toggleOrderDrawerOpen,
  setOrder,
  order
}) => {

  return (
    <Drawer
      anchor={"left"}
      open={isDrawerOrderOpen}
      onClose={toggleOrderDrawerOpen}
      data-testid="drawer-category"
      classes={{
        paper: styles.mainContainer,
      }}
    >
      <div className={styles.gridMenu}>
        <div className={styles.closeButton}></div>
        <div className={styles.menuPrincipal}>
          <span>ORDENAR</span>
        </div>
        <div className={styles.closeButton}>
          <Button
            onClick={toggleOrderDrawerOpen}
            disableTouchRipple
            color="primary"
            style={{ minWidth: "20px", height: "20px", padding: 0 }}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
        </div>
      </div>
      <div className={styles.drawerMain}>

        <List style={{ backgroundColor: 'white' }}>
          <ListItem
            button
            id="default"
            onClick={(e) => setOrder(e.target.id)}
            style={{ backgroundColor: '#F8F7F6', height: '40px', marginTop: '2px' }}
          >
            <span style={(order === "default") ? { color: '#e8927c', fontWeight: 'bold' } : undefined}>Me surpreenda</span>
          </ListItem>
          <ListItem
            button
            id="precoMaior"
            onClick={(e) => setOrder(e.target.id)}
            style={{ backgroundColor: '#F8F7F6', height: '40px', marginTop: '2px' }}
          >
            <span style={(order === "precoMaior") ? { color: '#e8927c', fontWeight: 'bold' } : undefined}>Maior Preço</span>
          </ListItem>
          <ListItem
            button
            id="precoMenor"
            onClick={(e) => setOrder(e.target.id)}
            style={{ backgroundColor: '#F8F7F6', height: '40px', marginTop: '2px' }}
          >
            <span style={(order === "precoMenor") ? { color: '#e8927c', fontWeight: 'bold' } : undefined}>Menor Preço</span>
          </ListItem>
        </List>


      </div>
    </Drawer>
  )
}

export default CategoryFilterDrawer
