import React from 'react'
import Grid from '@material-ui/core/Grid'
import { SelectedFilterType } from "types"
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

type filterPanelType = {
  selectedFilters: SelectedFilterType[],
  removeFilterOnly: (filterAttribute: string, filterValue: string) => void
  removeAllFilters: () => void
}

const filterPanel: React.FC<filterPanelType> = ({ selectedFilters, removeFilterOnly, removeAllFilters }) => {
  return (
    <Grid container style={(selectedFilters.length>0)?{padding:'4px 0'}:undefined}>
      
        {selectedFilters.map((filtro, index) => {
          return (

            <div
              key={index}
              style={{ backgroundColor: '#fff', padding: '6px 10px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', cursor: 'default', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <span style={{ marginRight: '3px', fontSize: '13px' }}>{filtro.attributeLabel} </span>
              <span style={{ display: 'flex', alignItems: 'center' }} onClick={() => removeFilterOnly(filtro.attribute, filtro.value)}>
                <CloseRoundedIcon style={{ width: '14px', height: '14px' }} />
              </span>
            </div>
          )
        })}
      
      {selectedFilters.length > 0 && <Grid item xs={12} style={{ marginTop: '10px', marginLeft:'3px', cursor: 'pointer' }}><span onClick={removeAllFilters} style={{ color: '#e8927c' }}>LIMPAR FILTROS</span></Grid>}

    </Grid>
  )
}

export default filterPanel
