import React from 'react'
import styles from '../../Category.module.scss'
import './FilterDrawerContent.scss'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Drawer from '@material-ui/core/Drawer'
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import VisualFiltersPanel from './components/VisualFiltersPanel'
import Button from '@material-ui/core/Button'

type filtersType = {
   isDrawerFilterOpen: any
   toggleFilterDrawerOpen: any
   filters: any
   handleFilter: any
   visualFilters: any
   handleApplyFilters: any
   removeAllVisualFilters: any
}

const filters: React.FC<filtersType> = ({ isDrawerFilterOpen, toggleFilterDrawerOpen, filters, handleFilter, visualFilters, handleApplyFilters, removeAllVisualFilters }) => {
   //console.log(isDrawerFilterOpen)
   return (
      <Drawer
         anchor={"left"}
         open={isDrawerFilterOpen}
         onClose={toggleFilterDrawerOpen}
         data-testid="drawer-category"
         classes={{
            paper: styles.mainContainer,
         }}
      >
         <div style={{ width: '100%', borderRight: '3px solid #f8f7f6' }}>
            <div className={styles.gridMenu}>
               <div className={styles.closeButton}></div>
               <div className={styles.menuPrincipal}>
                  <span>FILTROS</span>
               </div>
               <div className={styles.closeButton}>
                  <Button
                     onClick={toggleFilterDrawerOpen}
                     disableTouchRipple
                     color="primary"
                     style={{ minWidth: "20px", height: "20px", padding: 0 }}
                  >
                     <CloseRoundedIcon fontSize="small" />
                  </Button>
               </div>
            </div>
            {
               visualFilters.length > 0 &&
               <VisualFiltersPanel visualFilters={visualFilters} handleFilter={handleFilter} handleApplyFilters={handleApplyFilters} removeAllVisualFilters={removeAllVisualFilters}/>
            }

            {filters.filtros.map((filtro: any, idx: number) => {
               return (
                  <ExpansionPanel key={idx} style={{ backgroundColor: '#fff', marginTop: '3px' }} >
                     <ExpansionPanelSummary id="institutional" expandIcon={<ArrowForwardIosIcon style={{ width: '12px' }} />} classes={{ root: styles.expansionFilter, expanded: styles.expansionFilterExpanded }}>
                        {filtro.nomeFiltro}
                     </ExpansionPanelSummary>
                     <ExpansionPanelDetails>
                        <List style={{ width: '100%' }}>
                           {filtro.options.map((filterValue: any, idx: number) => {
                              return (
                                 <ListItem
                                    key={idx}
                                    button
                                    className={styles.listItem}
                                    onClick={() => handleFilter(filtro.atributoFiltro, filterValue.value, filtro.defaultFrontendLabel, filterValue.label)}
                                 >
                                    {filterValue.label}
                                 </ListItem>
                              )
                           })}
                        </List>
                     </ExpansionPanelDetails>
                  </ExpansionPanel>
               )
            })}
         </div>
      </Drawer>
   )
}

export default filters
